import { useState, useEffect, useRef } from "react";

import './Details.css';

import Comp_AddData from "components/AddData";
import Comp_SaveOrEdit from "components/SaveOrEdit";

import { GetListPag, SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Eye, Svg_EyeClose, Svg_User } from "services/SvgFile";
import { Reg_Checked, Reg_Page_UserDashboard } from "services/RegisterData";

import { colorIcon } from "fixedData";

export default function Page_User_Details(props){

    const divShowHidePassword = useRef();
    const [ statusShowHidePassword, setStatusShowHidePassword ] = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('access_dash'));
    const [ idPage, setIdPage ] = useState(props.userAccess === 1 ? GetListPag('currentPageId') : props.userId);
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ nivel, setNivel ] = useState(InitialData('nivel'));
    const [ file, setFile ] = useState(InitialData('file'));
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ name, setName ] = useState(InitialData('name'));
    const [ email, setEmail ] = useState(InitialData('email'));
    const [ password, setPassword ] = useState('');
    
    const [ companyName, setCompanyName ] = useState(InitialData('company_name'));
    const [ nameProject, setNameProject ] = useState(InitialData('name_project'));
    const [ companyCorporate, setCompanyCorporate ] = useState(InitialData('company_corporate_name'));
    const [ companyCnpj, setCompanyCnpj ] = useState(InitialData('company_cnpj'));
    const [ companyQtd, setCompanyQtd ] = useState(InitialData('qtd_project'));

    function InitialData(type){
        const newData = GetDataPage('access_dash').find(item => item.id == idPage);
        if(newData){
            return newData[type];
        }
        if(type === 'qtd_project'){
            return 0;
        }
        return '';
    }

    function CheckedQtd(value){
        if (/^[0-9]+$/.test(value)) {
            setCompanyQtd(value);
        }
    }
    
    function ShowHidePass(status){
        setStatusShowHidePassword(status);
        divShowHidePassword.current.type = statusShowHidePassword ? 'password' : 'text';
    }

    function RemovePhoto(){
        if(idPage != 0){
            SetModalData('Confirmation', { "origin": "access_dash", "type": "remove_photo", "id": idPage, "name": name });
            SetModalState('Confirmation', true);
        }
    }

    function ReturnPage(){
        if(props.userAccess === 1){            
            if(props.typePage == 0){
                SetListPag('currentPage', "client");
            }else {
                SetListPag('currentPage', "admin");
            }
            SetListPag('currentPageId', "remuve");
        }
    }

    function SaveFile(value){
        setFile(value);
        if(idPage !=0){
            SaveData('', value);
        }else {
            setFileStatus(true);
        }
    }

    function SaveInfData(){
        Reg_Page_UserDashboard(currentPage + "_" + props.typePage, props.userId, idPage, file, name, email, password, companyName, companyCorporate, companyCnpj, companyQtd, ()=>{ props.CallbackSuccess(); ReturnPage() }, ()=>{ props.CallbackError() });
    }

    function InvalidEmail(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "Email already registered" });
        SetModalState('ReturnResponse', true);
    }

    function SaveData(event, fileAdd){
        props.setLoading(true);
        if(event){
            event.preventDefault();
            if(idPage === 0){
                Reg_Checked("email", 0, email, ()=>{ SaveInfData() }, ()=>{ InvalidEmail() });
            }else {
                Reg_Page_UserDashboard(currentPage + "_" + props.typePage, props.userId, idPage, file, name, email, password, companyName, companyCorporate, companyCnpj, companyQtd, ()=>{ props.CallbackSuccess(); ReturnPage() }, ()=>{ props.CallbackError() });
            }
            
        }else {
            Reg_Page_UserDashboard(currentPage + "_" + props.typePage, props.userId, idPage, fileAdd, name, email, password, companyName, companyCorporate, companyCnpj, companyQtd, ()=>{ props.CallbackSuccess(); ReturnPage() }, ()=>{ props.CallbackError() });
        }
    }

    useEffect(()=>{
        RegisterDataPage('access_dash', setDataPage);
        return ()=>{
            UnRegisterDataPage('access_dash', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(props.userAccess === 1 ? GetListPag('currentPageId') : props.userId);
        setCurrentPage(GetListPag('currentPage'))

        setNivel(InitialData('nivel'))
        setFile(InitialData('file'));
        setFileStatus(false)
        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setPassword('');

        setNameProject(InitialData('name_project'));
        setCompanyName(InitialData('company_name'));
        setCompanyCorporate(InitialData('company_corporate_name'));
        setCompanyCnpj(InitialData('company_cnpj'));
        setCompanyQtd(InitialData('qtd_project'));
    }, [dataPage, idPage, props.status]);

    return(
        <form onSubmit={ SaveData } className="Page_User_Details">
            {
                props.userAccess === 1 ?
                <Comp_SaveOrEdit type="return" title="Account" ReturnPage={ ReturnPage } subtitle="Update and manage your account." />
                :
                <Comp_SaveOrEdit type="" title="Account" ReturnPage={ ReturnPage } subtitle="Update and manage your account." />
            }

            <div className="div_inf_user">
                <div className="div_inf">
                    <div className="div_img">
                        {
                            file == "" ?
                                name == "" ?
                                <Svg_User className="img" color={ colorIcon } />
                                :
                                <div className="img">
                                    {
                                        name.split(' ')[0][0]
                                    }
                                </div>
                            :
                                fileStatus ? 
                                <img alt="img user" src={ URL.createObjectURL(file) } className="img_" onClick={ ()=>{ props.OpenFile('img', URL.createObjectURL(file)) } } />
                                :
                                <img alt="img user" src={ file } className="img_" onClick={ ()=>{ props.OpenFile('img', file) } } />
                        }
                    </div>
                    <div className="btn">
                        <Comp_AddData id={ idPage } btn="Change your avatar" function={ SaveFile } listDisabled={ 0 } />
                    </div>
                    <div className="subtitle" onClick={ ()=>{ RemovePhoto() } }>
                        Remove my avatar and show my initials instead
                    </div>
                </div>

                <div className="data_user">
                    <div className="title">Account Details</div>
                    <div className="title div_input">
                        <div className="name_input">Name:</div>
                        <div className="input">
                            <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } placeholder="Enter your name" required />
                        </div>
                    </div>
                    <div className="title div_input">
                        <div className="name_input">Email:</div>
                        <div className="input">
                            <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } placeholder="Enter your email" required />
                        </div>
                    </div>
                    {
                        props.userAccess === 1 ? null :
                        <div className="title div_input">
                            <div className="name_input">Corporate name:</div>
                            <div className="input">
                                <input type="text" className="" onChange={ (e)=>{ setCompanyCorporate(e.target.value) } } value={ companyCorporate } placeholder="Enter your corporate name" required />
                            </div>
                        </div>
                    }
                    <div className="title div_input">
                        <div className="name_input">Password:</div>
                        <div className="input">
                            <input type="password" className="" onChange={ (e)=>{ setPassword(e.target.value) } } value={ password } placeholder="Enter your password" ref={ divShowHidePassword } required={ idPage == 0 ? true : false } />
                            {
                                password != '' ?
                                <div className="div_eye" onClick={ ()=>{ ShowHidePass(!statusShowHidePassword) } }>
                                    {
                                        statusShowHidePassword ?
                                        <Svg_Eye className="icons" color={ colorIcon } /> :
                                        <Svg_EyeClose className="icons" color={ colorIcon } />
                                    }
                                </div>
                                : null
                            }
                        </div>
                    </div>

                    {
                        props.userAccess === 1 ?
                            props.typePage === 0 ?
                                <>
                                    <div className="title" style={ { borderRadius: 0 } }>Company data</div>
                                    <div className="title div_input">
                                        <div className="name_input">Corporate name:</div>
                                        <div className="input">
                                            <input type="text" className="" onChange={ (e)=>{ setCompanyCorporate(e.target.value) } } value={ companyCorporate } placeholder="Enter your corporate name" required />
                                        </div>
                                    </div>
                                    <div className="title div_input">
                                        <div className="name_input">Name link:</div>
                                        <div className="input" title={ idPage === 0 ? null : "Once saved it cannot be changed..." }>
                                            {
                                                idPage === 0 ?
                                                <input type="text" className="" onChange={ (e)=>{ setCompanyName(e.target.value) } } value={ companyName } placeholder="Enter your name link" required />
                                                :
                                                <input type="text" className="" value={ nameProject } readOnly />
                                            }
                                        </div>
                                        <div className="name_input">Qtd project:</div>
                                        <div className="input qtd_project">
                                            <input type="text" className="" onChange={ (e)=>{ CheckedQtd(e.target.value) } } value={ companyQtd } placeholder="Number" maxLength={ 4 } required />
                                        </div>
                                    </div>
                                </>
                            : null
                        : null
                    }
                    <button type="submit" className="title btn_save">Save</button>
                </div>
            </div>
        </form>
    )
}