import { useState, useEffect } from "react";

import './Contents.css';

import Cookies from "universal-cookie";

import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Svg_Exit, Svg_LogoTipo, Svg_Project, Svg_Report, Svg_User, Svg_UserDashboard } from 'services/SvgFile';

import { colorIcon, cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePassw } from "fixedData";

import Page_User from "page/User";
import Page_Report from "page/Report";
import Page_Project from "page/Project";
import Page_User_Details from "page/User/Details";

export default function Contents(props){

    const cookies = new Cookies();
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));
    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ userFile, setUserFile ] = useState(GetUserData('file'));
    const [ userName, setUserName ] = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ] = useState(GetUserData('page'));

    const [ status, setStatus ] = useState(false);

    function CkickPage(value){
        SetListPag('currentPage', value);
    }

    function OpenUser(){
        if(userAccess === 1){
            SetListPag('currentPage', 'admin_details');
        }else {
            SetListPag('currentPage', 'client_details');
        }
        SetListPag('currentPageId', userId);
        setStatus(!status);
    }

    function CurrentPage(){        
        switch(currentPage) {
            case "admin":
                return <Page_User typePage={ 1 } userAccess={ userAccess } setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } />;
            case "admin_details":
                return <Page_User_Details typePage={ 1 } userAccess={ userAccess } setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } status={ status } />;

            case "client":
                return <Page_User typePage={ 0 } userAccess={ userAccess } setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } />;
            case "client_details":
                return <Page_User_Details typePage={ 0 } userAccess={ userAccess } setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } status={ status } />;

            case "report":
                return <Page_Report setLoading={ props.setLoading } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } />;

            default: 
                return <Page_Project setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } userAccess={ userAccess } />;
        }
    }

    function Logout(){
        // current date
        const day = new Date();

        // add 3 day
        const add3Days = new Date();
        add3Days.setDate(day.getDate() + 2);

        SetListPag('page', 'index');        
        cookies.set(typeCookie, 'index', { path: '/', expires: add3Days, domain: cookiesRegister });

        // add 3 day
        const add0Days = new Date();
        add0Days.setDate(day.getDate());
        
        cookies.set(typeCookieEmail, '', { path: '/', expires: add3Days, domain: cookiesRegister });
        cookies.set(typeCookiePassw, '', { path: '/', expires: add3Days, domain: cookiesRegister });
        cookies.set(typeCookiePage, '', { path: '/', expires: add3Days, domain: cookiesRegister });
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    return(
        <div className="Contents">
            <div className="div_menu_opt">
                <div className="div_logo">
                    <Svg_LogoTipo color="#ffffff" className="logotipo" />
                </div>
                <div className="div_opt_menu">

                    <div className={ currentPage == "index" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage("index"); } }>
                        <div className="div_icon">
                            <Svg_Project className="icons" color={ colorIcon } />
                        </div>
                        <div className="title">List of projects</div>
                    </div>
                    
                    {
                        userAccess === 1 ?
                            <>
                                <div className={ currentPage == "client" || currentPage == "client_details" || currentPage == "user_details_1" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage("client"); } }>
                                    <div className="div_icon">
                                        <Svg_UserDashboard className="icons" color={ colorIcon } />
                                    </div>
                                    <div className="title">Clients</div>
                                </div>
                                <div className={ currentPage == "admin" || currentPage == "admin_details" || currentPage == "user_details_1" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage("admin"); } }>
                                    <div className="div_icon">
                                        <Svg_UserDashboard className="icons" color={ colorIcon } />
                                    </div>
                                    <div className="title">Administrators</div>
                                </div>
                                <div className={ currentPage == "report"? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage("report"); } }>
                                    <div className="div_icon">
                                        <Svg_Report className="icons" color={ colorIcon } />
                                    </div>
                                    <div className="title">Report</div>
                                </div>
                            </>
                        :
                        <div className={ currentPage == "client_details" || currentPage == "user_details_1" ? "opt_menu opt_menu_active" : "opt_menu" } onClick={ ()=>{ CkickPage("client_details"); } }>
                            <div className="div_icon">
                                <Svg_UserDashboard className="icons" color={ colorIcon } />
                            </div>
                            <div className="title">Edit profile</div>
                        </div>
                    }
                </div>
                <div className="div_exit" onClick={ ()=>{ Logout() } }>
                    <div className="">
                        <Svg_Exit className="icons" color={ colorIcon } />
                    </div>
                    <div className="title">Logout</div>
                </div>
            </div>

            <div className="div_data_page">
                <div className="data_page">
                    <div className="inf_user">
                        <div className="div_logo">                        
                            <div className="logo">
                                <Svg_LogoTipo color="#ffffff" className="logotipo" />
                            </div>
                            <div className="title">THE REAL ESTATE PLATFORM</div>
                        </div>
                        <div className="div_data_user">
                            <div className="div_img_user" onClick={ ()=>{ OpenUser() } }>    
                                {
                                    userFile == "" ?
                                        userName == "" ?
                                        <Svg_User className="img" color={ colorIcon } />
                                        :
                                        <div className="img_profile">
                                            {
                                                userName.split(' ')[0][0]
                                            }
                                        </div>
                                    :
                                    <div className="img_profile_" style={ { backgroundImage: 'url("' + userFile + '")' } } />
                                }    
                            </div>
                        </div>
                    </div>
                    <div className="show_data_page">
                        { CurrentPage() }
                    </div>
                </div>
            </div>
        </div>
    )
}