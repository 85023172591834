import { useState, useEffect } from "react";

import Login from "templates/Login";
import Recover from "templates/Recover";
import Contents from "templates/Contents";

import { GetListPag, RegisterListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import PopUP_Confirmation from "popUp/Confirmation";
import PopUP_ReturnResponse from "popUp/ReturnResponse";
import PopUP_Historic from "popUp/Historic";
import PopUP_ShowFile from "popUp/ShowFile";
import PopUP_NewProject from "popUp/NewProject";

export default function App(props) {
    const [ loading, setLoading ] = useState(false); 
    const [ typePage, setTypePage ] = useState(GetListPag('page'));

    const [ url, setUrl ] = useState(window.location.href.split("/"));

    function OpenFile(type, file){
        console.log(type, file);
        if(file !=''){
            SetModalData('ShowFile', { "type": type, "file": file });
            SetModalState('ShowFile', true);
        }
    }

    function CallbackSuccess(value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": value });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(value){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": value });
        SetModalState('ReturnResponse', true);
    }

    function CheckedPage(){
        if(url[3]){
            let segund = url[3].split('=');  
            if(segund[0]){
                return <Recover setLoading={ setLoading } code={ segund[1] } setUrl={ setUrl } />;     
            }else {
                return <Login setLoading={ setLoading } loadingDataPage={ props.loadingDataPage } />;
            }
        }else {
            switch(typePage){
                case "dashboard":
                    return <Contents setLoading={ setLoading } OpenFile={ OpenFile } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />;
                default:   
                    return <Login setLoading={ setLoading } loadingDataPage={ props.loadingDataPage } />;
            }
        }
    }

    useEffect(()=>{
        RegisterListPag('page', setTypePage);
    }, []);

    return(
        <>
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <div className="dot-spinner">
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                    <div className="dot-spinner__dot" />
                </div>
            </div>
            {
                CheckedPage()
            }

            <PopUP_Historic setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            <PopUP_NewProject OpenFile={ OpenFile } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            <PopUP_Confirmation OpenFile={ OpenFile } setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            <PopUP_ReturnResponse setLoading={ setLoading } CallbackSuccess={ CallbackSuccess } CallbackError={ CallbackError } />
            <PopUP_ShowFile />
        </>
    );
}