import React, { useState, useEffect } from "react";

import './Report.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { colorIcon } from "fixedData";

import { Svg_Close } from "services/SvgFile";

export default function PopUP_Report(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ showData, setShowData ] = useState({});

    function ClosePopUp(){
        setShowData({});
        SetModalState('Report', false);
    }

    useEffect(()=>{
        RegisterModalData('Report', setModaldata);
        RegisterModalObserver('Report', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setShowData(modalData.data);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all InfReport">
                    <div className="div_data type_div">
                        <div className="title" style={ { borderColor: colorIcon } }>Information email</div>
                        <div className="close" style={ { borderColor: colorIcon } } onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color={ colorIcon } className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <table cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <th width="160" align="right">Name</th>
                                        <td align="left" style={ { borderTop: "1px solid #4a566e" } }>{ showData.name }</td>
                                    </tr>
                                    <tr>
                                        <th width="160" align="right">Surname</th>
                                        <td align="left">{ showData.last_name }</td>
                                    </tr>
                                    <tr>
                                        <th width="160" align="right">Company name</th>
                                        <td align="left">{ showData.company_name }</td>
                                    </tr>
                                    <tr>
                                        <th width="160" align="right">Phone</th>
                                        <td align="left">{ showData.phone }</td>
                                    </tr>
                                    <tr>
                                        <th width="160" align="right">Email</th>
                                        <td align="left">{ showData.email }</td>
                                    </tr>
                                    <tr>
                                        <th width="160" align="right">Where we met</th>
                                        <td align="left">{ showData.heard_about }</td>
                                    </tr>
                                    <tr>
                                        <th width="160" align="right">Message</th>
                                        <td align="left">{ showData.additional_message }</td>
                                    </tr>
                                    <tr>
                                        <th width="160" align="right">Contact via cell phone</th>
                                        <td align="left">{ showData.contact_phone ? "Yes" : "Not" }</td>
                                    </tr>
                                    <tr>
                                        <th width="160" align="right">Contact via email</th>
                                        <td align="left">{ showData.contact_email ? "Yes" : "Not" }</td>
                                    </tr>  
                                    <tr>
                                        <th width="160" align="right">Date</th>
                                        <td align="left">{ showData.register_date }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
