import { useState, useEffect, useRef } from "react";

import './LoadingProject.css';

export default function Comp_LoadingProject(props){

    const [ seconds, setSeconds ] = useState(props.time);
    const [ position, setPosition ] = useState(0);
    const [ showMsg, setShowMsg ] = useState([
        {
            "text": "We are creating your project, please wait"
        },
        {
            "text": "A new project is coming, please wait"
        },
        {
            "text": "You will be ready soon, please wait"
        }
    ]);

    useEffect(()=>{
        if (seconds > 0) {
            const timerId = setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);

            return () => clearTimeout(timerId);
        }
    }, [seconds]);

    useEffect(()=>{           
        const timerPosition = setTimeout(() => {
            setPosition(position + 1);
            if(position == 2){
                setPosition(0);
            }
        }, 5000);

        return () => clearTimeout(timerPosition);
    }, [position]);

    return(
        <div className="LoadingProject">
            <div className="ld-ripple">
                <div />
                <div />
            </div>
            {
                props.showMsg ? null : <div className="">Ira abrir uma nova aba no navegador em</div>
            }
            <div className="number">{ seconds }</div>
            {
                props.showMsg ? <div className="msg">{ showMsg[position].text }</div> : null
            }
        </div>
    )
}