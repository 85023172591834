import React, { useState, useEffect, useRef } from "react";

import './Historic.css';

import { GetUserData } from "interface/Users";
import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { colorIcon } from "fixedData";

import { Svg_Close } from "services/SvgFile";

export default function PopUP_Historic(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ list, setList ] = useState([]);

    function Reactivate(id, value){        
        SetModalData('Confirmation', { "origin": "access_dash", "type": "block_user_1", "id": id, "name": value });
        SetModalState('Confirmation', true);
    }

    function ClosePopUp(){
        SetModalState('Historic', false);
    }

    useEffect(()=>{
        RegisterModalData('Historic', setModaldata);
        RegisterModalObserver('Historic', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            console.log(modalData);
            setList(modalData.list);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp PopUpCenter">
                    <div className="all Historic">
                        <div className="div_data type_div">
                            <div className="title" style={ { borderColor: colorIcon } }>Deactivated users</div>
                            <div className="close" style={ { borderColor: colorIcon } } onClick={ ()=>{ ClosePopUp(); } }>
                                <Svg_Close color={ colorIcon } className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <table cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th align="center" width="20">#</th>
                                            <th align="left">Name</th>
                                            <th align="right" width="100">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.map((elem, index)=>{
                                                return(
                                                    <tr key={ index }>
                                                        <td align="center">{ index + 1 }</td>
                                                        <td align="left">{ elem.name }</td>
                                                        <td>
                                                            <div className="div_btn" onClick={ ()=>{ Reactivate(elem.id, elem.name) } }>
                                                                Reactivate
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
