import { useState, useEffect } from "react";

import './User.css';

import Comp_AddData from "components/AddData";
import Comp_SaveOrEdit from "components/SaveOrEdit";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_AddImg, Svg_Block, Svg_Delete, Svg_EditPencil } from "services/SvgFile";

import { colorIcon } from "fixedData";

import { Reg_Page_AddFile } from "services/RegisterData";

export default function Page_User(props){

    const [ type, setType ] = useState(props.typePage);
    const [ dataPage, setDataPage ] = useState(GetDataPage('access_dash'));
    const [ listActive, setListActive ] = useState(InitialData('active'));
    const [ listDisabled, setListDisabled ] = useState(InitialData('disabled'));

    function InitialData(typeData){
        // active
        if(typeData === 'active'){
            const newData = GetDataPage('access_dash').filter(item => item.status == 1);
            if(newData){
                return newData.filter(item => item.nivel == type);
            }
            return [];
        }
        // disabled
        if(typeData === 'disabled'){
            const newData = GetDataPage('access_dash').filter(item => item.status == 0);
            if(newData){
                return newData.filter(item => item.nivel == type);
            }
            return [];
        }
    }

    function AddFile(id, file){
        Reg_Page_AddFile(props.userId, id, file, ()=>{ props.CallbackSuccess(); }, ()=>{ props.CallbackError() });
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "access_dash", "type": type, "id": id, "name": value });
        SetModalState('Confirmation', true);
    }

    function NewOrEditUser(id){
        if(props.typePage == 0){
            SetListPag('currentPage', "client_details");
        }else {
            SetListPag('currentPage', "admin_details");
        }
        SetListPag('currentPageId', id);
    }

    useEffect(()=>{
        RegisterDataPage('access_dash', setDataPage);
        return ()=>{
            UnRegisterDataPage('access_dash', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('access_dash'));
        setListActive(InitialData('active'));
        setListDisabled(InitialData('disabled'));
    }, [dataPage]);

    useEffect(()=>{
        setType(props.typePage);
        setListActive(InitialData('active'));
        setListDisabled(InitialData('disabled'));
    }, [type, props.typePage]);

    return(
        <div className="InfPage">
            <Comp_SaveOrEdit type="" title={ props.typePage === 0 ? "Client list" : "Administrator control" } subtitle="Add and control user access." />

            {/* 
            <div className="show_opt_access">
                <div className="opt_data">
                    <div className="div_type_data">
                        <div className={ type === 1 ? "show_data show_data_active" : "show_data" } onClick={ ()=>{ setType(1); } }>
                            Administrators
                        </div>
                    </div>
                    <div className="div_type_data">
                        <div className={ type === 0 ? "show_data show_data_active" : "show_data" } onClick={ ()=>{ setType(0); } }>
                            Clients
                        </div>
                    </div>
                </div>
            </div> 
            */}

            <Comp_AddData id={ 0 } btn="Add New User" function={ NewOrEditUser } listDisabled={ listDisabled } />

            <table cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <td className="border_top" width="20" align="center">#</td>
                        {
                            type == 0 ?
                            <td className="border_top">Company</td>
                            : null
                        }
                        <td className="border_top">Name</td>
                        <td className="border_top" width="180">Email</td>
                        <td className="border_top" width="40">Avatar</td>
                        <td className="border_top" width="60" style={ { textAlign: "right" } }>#</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        listActive.map((elem, index)=>{
                            return(
                                <tr key={ index }>
                                    <td>{ index + 1 }</td>
                                    {
                                        type == 0 ?
                                        <td>{ elem.company_name }</td>
                                        : null
                                    }
                                    <td>{ elem.name }</td>
                                    <td>{ elem.email }</td>
                                    <td>
                                        {
                                            elem.file !='' ?
                                            <div className="div_add_file">
                                                <div alt={ "img point_file_" + index } style={ { backgroundImage: 'url("' + elem.file + '")' } } className="img_point" onClick={ ()=>{ props.OpenFile('img', elem.file) } } />
                                            </div>
                                            :
                                            <label>
                                                <div className="div_add_file">
                                                    <input type="file" onChange={ (e)=>{ AddFile(elem.id, e.target.files[0]); } } accept="image/*" />
                                                    <Svg_AddImg className="icons" color={ colorIcon } />
                                                </div>
                                            </label>
                                        }
                                    </td>
                                    <td>
                                        <div className="div_opt">
                                            {
                                                props.userId === elem.id ? null :
                                                <>               
                                                    <div className="" onClick={ ()=>{ DeleteData('delete_user', elem.id, elem.name); } }>
                                                        <Svg_Delete className="icons" color={ colorIcon } />
                                                    </div>|                                            
                                                    <div className="" onClick={ ()=>{ DeleteData('block_user_0', elem.id, elem.name); } }>
                                                        <Svg_Block className="icons" color={ colorIcon } />
                                                    </div>|  
                                                </>
                                            }  
                                            <div className="" onClick={ ()=>{ NewOrEditUser(elem.id); } }>
                                                <Svg_EditPencil className="icons" color={ colorIcon } />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}