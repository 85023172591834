
let DataPage = {
    "access_dash" : [],
    "project" : [],
    "report" : []
};

let NotifyDataPage = {
    "access_dash" : [],
    "project" : [],
    "report" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["access_dash"].forEach(element => { element(value['access_dash']); });
    NotifyDataPage["project"].forEach(element => { element(value['project']); });
    NotifyDataPage["report"].forEach(element => { element(value['report']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}