// Connection type
export const connectionPage = '.';

// Cookies type
// export const cookiesRegister = 'localhost';
export const cookiesRegister = '.go-bees.com';

// Cookies name
export const typeCookie = 'dash_bees';
export const typeCookiePassw = 'dash_bees_passw';
export const typeCookieEmail = 'dash_bees_email';
export const typeCookiePage = 'dash_bees_page';
export const typeCookiePageId = 'dash_bees_page_id';

export const colorIcon = '#DFDFDF';
export const colorIconSecondary = '#FFBD66';