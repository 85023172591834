import './SaveOrEdit.css';

export default function Comp_SaveOrEdit(props){

    return(
        <div className="Comp_SaveOrEdit">
            <div className="">
                <div className="title_page">{ props.title }</div>
                <div className="subtitle_page">{ props.subtitle }</div>
            </div>

            {
                props.type === 'return' ?
                <div className="show_opt_access">
                    <div className="opt_data">
                        <div className="div_type_data">
                            <div className="show_data" onClick={ ()=>{ props.ReturnPage(); } }>
                                Voltar
                            </div>
                        </div>
                    </div>
                </div>
                : null
            }
        </div>
    )
}