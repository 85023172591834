import { useState, useEffect } from "react";

import './Project.css';

import Cookies from 'universal-cookie';

import Comp_AddData from "components/AddData";
import Comp_SaveOrEdit from "components/SaveOrEdit";
import Comp_LoadingProject from "components/LoadingProject";

import { Svg_Circle, Svg_Delete, Svg_EditPencil, Svg_MenuPoint, Svg_Search } from "services/SvgFile";

import { colorIcon, cookiesRegister, typeCookieEmail, typeCookiePassw } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function Page_Project(props){
    
    const cookies = new Cookies();
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ search, setSearch ] = useState('');
    const [ dataPage, setDataPage ] = useState(GetDataPage('project'));
    const [ listProject, setListProject ] = useState(InitialData());

    function InitialData(){
        if(props.userAccess === 0){
            const newData = GetDataPage('project').filter(item => item.id_client == props.userId);
            return newData;
        }else {
            return GetDataPage('project');
        }
    }

    function InputSearch(value){
        const newList = [];
        if(value){
            GetDataPage('project').forEach(item =>{
                if(item.status !== 0){
                    if(item.project_title.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                        newList.push(item);
                    }
                    if(item.project_description.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                        newList.push(item);
                    }
                    if(item.map_title.toLowerCase().indexOf(value.toLowerCase()) !== -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListProject(duplicate);
        }else {
            setListProject(InitialData());
        }
        setSearch(value);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "project", "type": type, "id": id, "name": value });
        SetModalState('Confirmation', true);
    }
    
    function OpenDoubts(id, index, value){
        const newData = [...listProject];
        newData[index]['statusOpt'] = value;
        setListProject(newData);

        let divOpt = document.getElementById(id);
        if(value){
            divOpt.style.maxHeight = divOpt.scrollHeight + "px";
            divOpt.style.border = "1px solid #808FA3";
        }else {
            divOpt.style.maxHeight = null;
            setTimeout(() => {
                divOpt.style.border = 'none';
            }, 460);
        }
    }

    function OpenDash(url){
        // current date
        const day = new Date();

        // add 3 day
        const add3Days = new Date();
        add3Days.setDate(day.getDate() + 2);

        let sepInf = url.split('/');

        cookies.set(sepInf[3] + '_project', 'dashboard', { path: '/', expires: add3Days, domain: cookiesRegister });
        cookies.set(sepInf[3] + '_project_page', 'index', { path: '/', expires: add3Days, domain: cookiesRegister });
        cookies.set(sepInf[3] + '_project_email', cookies.get(typeCookieEmail), { path: '/', expires: add3Days, domain: cookiesRegister });
        cookies.set(sepInf[3] + '_project_passw', cookies.get(typeCookiePassw), { path: '/', expires: add3Days, domain: cookiesRegister });

        setStatusLoading(true);
        setTimeout(() => {
            window.open(url, '_blank');

            setStatusLoading(false);
        }, 3100);
    }

    function NewProject(){
        SetModalState('NewProject', true);
    }

    useEffect(()=>{
        RegisterDataPage('project', setDataPage);
        return ()=>{
            UnRegisterDataPage('project', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('project'));
        setListProject(InitialData());
    }, [dataPage]);
    
    return(
        <div className="PageProject">
            {
                statusLoading ? <Comp_LoadingProject showMsg={ false } time={ 3 } /> : null
            }

            <Comp_SaveOrEdit type="" title="List of projects" subtitle="Add and control all projects added to the BEES platform." />
            
            <Comp_AddData id={ 0 } btn="Add Project" function={ NewProject } listDisabled={ 0 } />

            <div className="list_project">
                {
                    listProject.length > 0 ?
                        <div className="div_search">
                            <input type="text" className="" onChange={ (e)=>{ InputSearch(e.target.value); } } value={ search } placeholder="Enter your search" />
                            <Svg_Search className="icons" color={ colorIcon } />
                        </div>
                    : null
                }

                <div className={ listProject.length > 0 ? "show_list_project" : "show_list_project no_found_data" }>  
                    {  
                        listProject.length > 0 ?
                        listProject.map((elem, index)=>{
                            return(
                                <div className="div_project" key={ index }>
                                    <div className="project_title">
                                        <div className="">{ elem.project_title }</div>
                                        <div className="name_client">{ elem.name_client }</div>
                                    </div>
                                    <div className="project_cover" style={ { backgroundImage: 'url("' + elem.cover  + '")' } } />
                                    <div className="project_btn">
                                        <div className="div_btn">
                                            {
                                                elem.status === 1 ? 
                                                <>
                                                    <a className="btn" href={ elem.url_site } target="_blank">Platform</a>
                                                    <div className="btn" onClick={ ()=>{ OpenDash(elem.url_dashboard) } }>Dashboard</div>
                                                </> : 
                                                <div className="btn disabled">Project disabled</div>
                                            }
                                        </div>
                                        <div className="div_opt">
                                            <div className={ elem.statusOpt ? "div_icon div_icon_active" : "div_icon" } onClick={ ()=>{ OpenDoubts("opt_" + index, index, !elem.statusOpt) } }>
                                                <Svg_MenuPoint className="icons" color={ colorIcon } />
                                            </div>
                                            <div className="show_opt_click" id={ "opt_" + index }>
                                                {/* 
                                                <div className="div_show_opt" onClick={ ()=>{ OpenDoubts("opt_" + index, index, false) } }>
                                                    <div className="">Edit project</div>
                                                    <div className="">
                                                        <Svg_EditPencil className="icons" color={ colorIcon } />
                                                    </div>
                                                </div> 
                                                */}
                                                <div className="div_show_opt" onClick={ ()=>{ OpenDoubts("opt_" + index, index, false); DeleteData('delete_project', elem.id, elem.project_title) } }>
                                                    <div className="">Remove project</div>
                                                    <div className="">
                                                        <Svg_Delete className="icons" color={ colorIcon } />
                                                    </div>
                                                </div>
                                                {
                                                    elem.status === 1 ? 
                                                    <div className="div_show_opt" onClick={ ()=>{ OpenDoubts("opt_" + index, index, false); DeleteData('disable_project_0', elem.id, elem.project_title) } }>
                                                        <div className="">Disable Project</div>
                                                        <div className="">
                                                            <Svg_Circle className="icons" color={ colorIcon } />
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="div_show_opt" onClick={ ()=>{ OpenDoubts("opt_" + index, index, false); DeleteData('disable_project_1', elem.id, elem.project_title) } }>
                                                        <div className="">Reactivate Project</div>
                                                        <div className="">
                                                            <Svg_Circle className="icons" color={ colorIcon } />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        : 
                        <div className="no_data">No projects found...</div>
                    }
                </div>
            </div>
        </div>
    )
}