import { useState, useEffect, useRef } from "react";

import './Login.css';

import { Access } from "services/AccessLogin";

import { Svg_Eye, Svg_EyeClose, Svg_Login, Svg_LogoTipo_1 } from "services/SvgFile";
import { colorIcon } from "fixedData";

export default function Login(props){

    const divShowHidePassword = useRef();
    const [ statusShowHidePassword, setStatusShowHidePassword ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');
    
    function ShowHidePass(status){
        setStatusShowHidePassword(status);
        divShowHidePassword.current.type = statusShowHidePassword ? 'password' : 'text';
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Access('logar', email, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        props.loadingDataPage();
    }

    function CallbackError(){
        props.setLoading(false);
        setMsgError('E-mail ou senha incorreta!');
    }
    
    return (
        <>
            <form className="Login" onSubmit={ SaveData }>
                <div className="div_logo">
                    <Svg_LogoTipo_1 className="logotipo" />
                </div>

                <div className="div_text">
                    <div className="title">Welcome!</div>
                    <div className="subtitle">Please enter your login access.</div>
                    {
                        msgError !='' ? <div className="subtitle msg_error">{ msgError }</div> : null
                    }
                </div>

                <div className="div_opt_login">
                    <div className="div_inf_login">
                        <div className="div_input">
                            <input type="email" className="" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email } placeholder="Enter your email" required />
                        </div>
                        <div className="div_input">
                            <input type="password" className="" onChange={ (e)=>{ setPassword(e.target.value) } } value={ password } placeholder="Password" ref={ divShowHidePassword } required />
                            <div className="div_eye" onClick={ ()=>{ ShowHidePass(!statusShowHidePassword) } }>
                                {
                                    statusShowHidePassword ?
                                    <Svg_Eye className="icons" color={ colorIcon } /> :
                                    <Svg_EyeClose className="icons" color={ colorIcon } />
                                }
                            </div>
                        </div>
                        <button className="btn">
                            <div className="">Login</div>
                            <div className="">
                                <Svg_Login className="icons" color={ colorIcon } />
                            </div>
                        </button>
                    </div>
                    {/* 
                    <div className="div_loga">
                        <label>
                            <input type="checkbox" />
                            <span className="custom-checkbox"></span>
                            <div className="">Remember me</div>
                        </label>
                        <div className="">
                            Forgot password?
                        </div>
                    </div> 
                    */}
                </div>
            </form>
        </>
    )
}