import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { GetDataPage, SetListDataSingle } from 'interface/Data';

import { cookiesRegister, typeCookieEmail, typeCookiePassw } from 'fixedData';

// Alt Status data
export function Reg_AltStatus(userId, origin, type, id, CallbackSuccess, CallbackError){
    const data    = new FormData();
    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data    = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url : process.env.REACT_APP_API_URL + 'inc/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        switch (response.data) {
            case "Email successfully sent":
                    CallbackSuccess();
                break;
            case "Email not sent":
                    CallbackError_Send();
                break;
            case "Email not found":
                    CallbackError_NotEmail();
                break;
            case "Error sending message":
                    CallbackError();
                break;
        }

    }).catch((error)=>{

        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url : process.env.REACT_APP_API_URL + 'inc/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        if(response.data == "Connected" || response.data == "Email successfully sent"){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Page User dashboard
export function Reg_Page_UserDashboard(namePage, userId, idPage, file, name, email, password, companyName, companyCorporate, companyCnpj, companyQtd, CallbackSuccess, CallbackError){
    const data = new FormData();
    const cookies = new Cookies();
    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register');

    data.append('namePage', namePage);
    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('file', file);
    data.append('name', name);
    data.append('email', email);
    data.append('password', password);
    
    data.append('company_name', companyName);
    data.append('company_corporate_name', companyCorporate);
    data.append('company_cnpj', companyCnpj);
    data.append('qtd_project', companyQtd);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/access_dash.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }

        if(userId == idPage){  
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 3);          

            if(response.data.password !=''){
                cookies.set(typeCookiePassw, response.data.password, { path: '/', expires: add3Days, domain: cookiesRegister });
            }

            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days, domain: cookiesRegister });

            SetUserData('file', response.data.file);
            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Page User dashboard - add file
export function Reg_Page_AddFile(userId, idPage, file, CallbackSuccess, CallbackError){
    const data = new FormData();
    const cookies = new Cookies();
    data.append('type_page', 'access_dash');
    data.append('edit_data', 'add_new_file');

    data.append('idPage', idPage);
    data.append('file', file);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/access_dash.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        
        if(userId == idPage){
            SetUserData('file', response.data.file);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// New project
export function Reg_NewProject(userId, clientId, projectTitle, projectDescription, mapIcon, mapLatitude, mapLongitude, mapTitle, endLogo, endCover, CallbackSuccess, CallbackError){
    const data    = new FormData();

    data.append('type_page', 'project');
    data.append('edit_data', 'new_project');
    data.append('userId', userId);
    data.append('clientId', clientId);
    data.append('projectTitle', projectTitle);
    data.append('projectDescription', projectDescription);
    data.append('mapIcon', mapIcon);
    data.append('mapLatitude', mapLatitude);
    data.append('mapLongitude', mapLongitude);
    data.append('mapTitle', mapTitle);
    data.append('endLogo', endLogo);
    data.append('endCover', endCover);

    Axios({
        url : process.env.REACT_APP_API_URL + 'page/project.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);        
        SetListDataSingle('project', response.data.project);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Checked data
export function Reg_Checked(type, idData, dataChecked, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'checked_data');
    data.append('edit_data', type);
    data.append('idData', idData);
    data.append('data', dataChecked);

    Axios({
        url : process.env.REACT_APP_API_URL + 'inc/checked_data.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        if(response.data.result){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}