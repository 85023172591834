import React, { useState, useEffect, useRef } from "react";

import './NewProject.css';

import TextareaAutosize from 'react-textarea-autosize';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import GoogleMapReact from 'google-map-react';

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Svg_AddData, Svg_Arrow, Svg_Close, Svg_Location } from "services/SvgFile";
import { Reg_Checked, Reg_NewProject } from "services/RegisterData";
import { colorIcon } from "fixedData";
import Comp_LoadingProject from "components/LoadingProject";
import { cepMask } from "services/Mask";

export default function PopUP_NewProject(props){

    const ShowListClient = useRef();
    const [ statusQtdProject, setStatusQtdProject ] = useState(true);
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ userName, setUserName ] = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ listUser, setListUser ] = useState(GetDataPage('access_dash'));

    const [ position, setPosition ] = useState(1);
    const [ cepSearch, setCepSearch ] = useState('');

    /* position 1 */
    const [ clientId, setClientId ] = useState(0);
    const [ clientName, setClientName ] = useState('Click here to list customers');
    const [ clientStatus, setClientStatus ] = useState(false);
    const [ projectTitle, setProjectTitle ] = useState('');
    const [ projectDescription, setProjectDescription ] = useState('');
    /* end */

    /* position 2 */
    const [ mapIcon, setMapIcon ] = useState('');
    const [ mapIconStatus, setMapIconStatus ] = useState(false);
    const [ mapStatus, setMapStatus ] = useState(false);
    const [ mapLatitude, setMapLatitude ] = useState('');
    const [ mapLongitude, setMapLongitude ] = useState('');
    const [ mapTitle, setMapTitle ] = useState('');
    /* end */

    /* position 3 */
    const [ endLogo, setEndLogo ] = useState('');
    const [ endLogoStatus, setEndLogoStatus ] = useState(false);
    const [ endCover, setEndCover ] = useState('');
    const [ endCoverStatus, setEndCoverStatus ] = useState(false);
    /* end */

    /* map */
    const mapStyles = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ];    
    const createMapOptions = (maps) => {
        return {
            zoomControl: false, // Desativa o controle de zoom
            fullscreenControl: false, // Desativa o controle de tela cheia
            panControl: true,
            mapTypeControl: false,
            scrollwheel: true,
            styles: mapStyles,
        };
    };
    /* end */

    function ShowLatLng(value) {
        let newCEP = value.replaceAll('-', '');
        newCEP = newCEP.replaceAll(' ', '');
        const apiKey = 'AIzaSyAUvZ2klamI0roIu6kh3UrHHtyD4xFLkg0'; 
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${newCEP}&key=${apiKey}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
            if (data.status === 'OK') {
                const location = data.results[0].geometry.location;
                const latitude = location.lat;
                const longitude = location.lng;
                setMapLatitude(latitude);
                setMapLongitude(longitude);
            } else {
            }
        })
        .catch(error => console.error('Erro na solicitação:', error));
    }

    function ClosePopUp(){
        setPosition(1);
        setCepSearch('');

        /* position 1 */
        setClientId(0);
        setClientName('Click here to list customers');
        setClientStatus(false);
        setProjectTitle('');
        setProjectDescription('');
        /* end */

        /* position 2 */
        setMapIcon('');
        setMapIconStatus(false);
        setMapStatus(false);
        setMapLatitude(49.10130476881723);
        setMapLongitude(-122.66300202361981);
        setMapTitle('');
        /* end */

        /* position 3 */
        setEndLogo('');
        setEndLogoStatus(false);
        setEndCover('');
        setEndCoverStatus(false);
        /* end */

        SetModalState('NewProject', false);
    }

    function ClickePoint(value) {
        setMapStatus(true);
        setMapLatitude(value.lat);
        setMapLongitude(value.lng);       
    }

    function AddFile(setFile, setFileStatus, value){
        if(value){
            setFile(value);
            setFileStatus(true);
        }else {
            setFile('');
            setFileStatus(false);
        }
    }
    
    function OpenDoubts(value){
        setClientStatus(value);
        if(ShowListClient.current){
            if(value){
                ShowListClient.current.style.maxHeight = "200px";
                ShowListClient.current.style.border = "1px solid #808FA3";
            }else {
                ShowListClient.current.style.maxHeight = null;
                setTimeout(() => {
                    ShowListClient.current.style.border = 'none';
                }, 460);
            }
        }
    }

    function CheckedInf(){
        if(position === 1){
            if(clientId != 0 && projectTitle !='' && projectDescription !=''){
                setPosition(2);
            }else {
                SetModalData('ReturnResponse', { "page": "erro", "text": "You must fill in all the options to advance!" });
                SetModalState('ReturnResponse', true);
            }
        }
        if(position === 2){
            if(mapTitle !='' && mapIcon !='' && mapLatitude !='' && mapLongitude !=''){
                setPosition(3);
            }else {
                SetModalData('ReturnResponse', { "page": "erro", "text": "You must fill in all the options to advance!" });
                SetModalState('ReturnResponse', true);
            }
        }
        if(position === 3){
            if(endLogo == '' || endCover == ''){
                SetModalData('ReturnResponse', { "page": "erro", "text": "You must fill in all the options to advance!" });
                SetModalState('ReturnResponse', true);
            }
        }
    }

    function CheckedQtdProject(id){
        Reg_Checked("qtd_project", 0, id, ()=>{ setStatusQtdProject(true) }, ()=>{ setStatusQtdProject(false) });
    }

    function SaveInfData(){
        Reg_NewProject(userId, clientId, projectTitle, projectDescription, mapIcon, mapLatitude, mapLongitude, mapTitle, endLogo, endCover, ()=>{ LoadingPage(); }, ()=>{ props.CallbackError() });
    }

    function LoadingPage(){
        props.setLoading(false);
        setStatusLoading(true);
        setTimeout(() => {  
            ClosePopUp();
            setStatusLoading(false);
            props.CallbackSuccess(); 
        }, 60000);
    }

    function InvalidName(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "Project name already registered" });
        SetModalState('ReturnResponse', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Checked("project", clientId, projectTitle, ()=>{ SaveInfData() }, ()=>{ InvalidName() });
    }

    useEffect(()=>{
        RegisterModalData('NewProject', setModaldata);
        RegisterModalObserver('NewProject', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setUserId(GetUserData('id'));
            setUserAccess(GetUserData('access'));
            setListUser(GetDataPage('access_dash'));

            setPosition(1);
            setCepSearch('');

            /* position 1 */
            setClientId(0);
            setClientName('Click here to list customers');
            setClientStatus(false);
            setProjectTitle('');
            setProjectDescription('');
            /* end */

            /* position 2 */
            setMapIcon('');
            setMapIconStatus(false);
            setMapStatus(false);
            setMapLatitude('');
            setMapLongitude('');
            setMapTitle('');
            /* end */

            /* position 3 */
            setEndLogo('');
            setEndLogoStatus(false);
            setEndCover('');
            setEndCoverStatus(false);
            /* end */
            
            if(userAccess === 0){
                CheckedQtdProject(userId);
                setClientId(userId);
                setClientName(userName);
            }
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form className="PopUp" onSubmit={ SaveData }>
                
                {
                    statusLoading ? <Comp_LoadingProject showMsg={ true } time={ 60 } /> : null
                } 
                
                <div className="all NewProject">
                    <div className="div_data type_div">
                        <div className="title">
                            ADD PROJECT
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#ffffff" className="icons" />
                        </div>
                    </div>
                    <div className="div_data column" style={ { paddingTop: 0 } }>
                        
                        {
                            statusQtdProject ? 
                            <>                            
                                <div className="div_position">
                                    <div className={ position >= 1 ? "number" : "number disabled" } onClick={ ()=>{ setPosition(1) } }>01</div>
                                    <div className={ position >= 1 ? "line" : "line disabled" } />
                                    <div className={ position >= 2 ? "number" : "number disabled" } onClick={ ()=>{ setPosition(2) } }>02</div>
                                    <div className={ position >= 3 ? "line" : "line disabled" } />
                                    <div className={ position >= 3 ? "number" : "number disabled" } onClick={ ()=>{ setPosition(3) } }>03</div>
                                </div>

                                <div className="show_data_opt">
                                    <div className={ position >= 1 ? "title_add title_left" : "title_add title_left disabled" } onClick={ ()=>{ setPosition(1) } }>
                                        Project Infos
                                    </div>
                                    <div className={ position >= 2 ? "title_add title_center" : "title_add title_center disabled" } onClick={ ()=>{ setPosition(2) } }>
                                        Add Point Location
                                    </div>
                                    <div className={ position >= 3 ? "title_add title_right" : "title_add title_right disabled" } onClick={ ()=>{ setPosition(3) } }>
                                        Add Cover
                                    </div>
                                </div>

                                <div className="title_inf">
                                    <div className="">
                                        {
                                            position === 1 ?
                                            "Please fill out the informations about the project."
                                            :
                                                position === 2 ?
                                                "Select the point directly on the map or by address informations."
                                                :
                                                "Add Logo and Cover to the project."
                                        }
                                    </div>
                                    <div className="div_btn">
                                        {
                                            statusQtdProject ?
                                            <>
                                                {
                                                    position === 1 ? null : 
                                                    <div className="btn" onClick={ ()=>{ setPosition(position - 1) } }>Back</div>
                                                }
                                                {
                                                    position === 3 ? 
                                                        endLogo !='' && endCover !='' ?
                                                        <button className="btn" onClick={ ()=>{ CheckedInf() } }>Save</button>
                                                        :
                                                        <div className="btn" onClick={ ()=>{ CheckedInf() } }>Missing Data</div>
                                                    :
                                                    <div className="btn" onClick={ ()=>{ CheckedInf() } }>Next</div>
                                                } 
                                            </> 
                                            : null
                                        }
                                    </div>
                                </div>                     
                                {
                                    position === 1 ?
                                    <>
                                        {
                                            userAccess === 0 ? null
                                            :
                                            <div className={ statusQtdProject ? "list_client" : "list_client alt_gap" }>
                                                <div className="div_client" onClick={ ()=>{ OpenDoubts(!clientStatus); } }>
                                                    <div className="show_client">
                                                        { clientName }
                                                    </div>
                                                    <div className="div_icon">
                                                        <Svg_Arrow className={ clientStatus ? "icons icons_active" : "icons" } color={ colorIcon } />
                                                    </div>
                                                </div>
                                                <div className={ statusQtdProject ? "show_list_client" : "show_list_client not_list" } ref={ ShowListClient }>
                                                    {
                                                        listUser.map((elem, index)=>{
                                                            if(elem.nivel == 0){
                                                                return (
                                                                    <div className="name_client" key={ index } onClick={ ()=>{ OpenDoubts(false); setClientId(elem.id); setClientName(elem.name);CheckedQtdProject(elem.id) } }>
                                                                        { elem.company_corporate_name }
                                                                    </div>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </div>
                                                <div className="title_inf">
                                                    The client should be added as user first, so you can select the client project here.
                                                </div>
                                            </div>
                                        }
                                        {
                                            statusQtdProject ? 
                                            <div className="data_project">
                                                <div className="div_title">Title Project*</div>
                                                <div className="project_title">
                                                    <input type="text" className="" onChange={ (e)=>{ setProjectTitle(e.target.value); setMapTitle(e.target.value) } } value={ projectTitle } maxLength="140" placeholder="Enter your title" required />
                                                </div>
                                                <div className="div_title div_title_border">Description*</div>
                                                <div className="project_text">
                                                    <TextareaAutosize className="textarea" onChange={ (e)=>{ setProjectDescription(e.target.value) } } value={ projectDescription.replace(/<br>/g,'\n') } placeholder="Enter your description" required />
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </>
                                    :
                                        position === 2 ?
                                        <>
                                            <div className="show_map">
                                                
                                                <div className="show_data_map">
                                                    <div className="div_icon">
                                                        <div className="div_btn">
                                                            Enter your CEP
                                                            <input type="text" className="input_cep" onChange={ (e)=>{ setCepSearch(e.target.value); ShowLatLng(e.target.value) } } value={ cepSearch } placeholder="" />
                                                        </div>
                                                        <Svg_Location className="icons" color={ colorIcon } />
                                                        <div className="">Add coordination to the point location.</div>
                                                    </div>
                                                    <div className="div_add_icon">
                                                        <label>
                                                            <input type="file" onChange={ (e)=>{ AddFile(setMapIcon, setMapIconStatus, e.target.files[0]); } } accept="image/*" />
                                                            <div className="icon_add">
                                                                <Svg_AddData className="icons" color={ colorIcon } />
                                                            </div>
                                                            <div className="title_logo">Add icon logo</div>
                                                        </label>
                                                        {
                                                            mapIconStatus ?
                                                            <div className="div_icon_add" style={ { backgroundImage: 'url("' + URL.createObjectURL(mapIcon) + '")' } } onClick={ ()=>{ props.OpenFile('img', URL.createObjectURL(mapIcon)) } } />
                                                            : null
                                                        }
                                                    </div>
                                                    <div className="show_data_input">
                                                        <div className="div_input">
                                                            <div className="title_input">Title Place*</div>
                                                            <div className="input">
                                                                <input type="text" className="" onChange={ (e)=>{ setMapTitle(e.target.value) } } value={ mapTitle == "" ? projectTitle : mapTitle } placeholder="Enter your title place" maxLength={ 140 } required />
                                                            </div>
                                                        </div>
                                                        <div className="div_input div_input_end">
                                                            <div className="title_input title_end">Latitude*</div>
                                                            <div className="input">
                                                                <input type="text" className="" onChange={ (e)=>{ setMapLatitude(e.target.value) } } value={ mapLatitude } placeholder="Enter your latitude" maxLength={ 40 } required />
                                                            </div>
                                                            <div className="title_input title_end_left">Longitude*</div>
                                                            <div className="input">
                                                                <input type="text" className="" onChange={ (e)=>{ setMapLongitude(e.target.value) } } value={ mapLongitude } placeholder="Enter your longitude" maxLength={ 40 } required />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={ { key: "AIzaSyC4EnMG7H-fmsU4vXtSvLVhnzPZTEqS4nw" } }
                                                    defaultCenter={ { "lat": 49.8600952, "lng": -119.4960408 } }
                                                    defaultZoom={ 12 }  
                                                    onClick={ (e)=>{ ClickePoint(e) } }                                        
                                                    options={ createMapOptions }
                                                    onGoogleApiLoaded={({ map, maps }) => {
                                                    }}
                                                    mapTypeId="roadmap"
                                                    yesIWantToUseGoogleMapApiInternals
                                                >
                                                </GoogleMapReact>
                                            </div>
                                        </>
                                        :
                                        <div className="show_map data_end">
                                            <div className="show_data_map">
                                                <div className="div_add_icon">
                                                    <label>
                                                        <input type="file" onChange={ (e)=>{ AddFile(setEndLogo, setEndLogoStatus, e.target.files[0]); } } accept="image/*" />
                                                        <div className="icon_add">
                                                            <Svg_AddData className="icons" color={ colorIcon } />
                                                        </div>
                                                        <div className="title_logo">Add Logo</div>
                                                    </label>
                                                    {
                                                        endLogoStatus ?
                                                        <div className="div_icon_add" style={ { backgroundImage: 'url("' + URL.createObjectURL(endLogo) + '")' } } onClick={ ()=>{ props.OpenFile('img', URL.createObjectURL(endLogo)) } } />
                                                        : null
                                                    }
                                                </div>
                                                <div className="div_add_icon">
                                                    <label>
                                                        <input type="file" onChange={ (e)=>{ AddFile(setEndCover, setEndCoverStatus, e.target.files[0]); } } accept="image/*" />
                                                        <div className="icon_add">
                                                            <Svg_AddData className="icons" color={ colorIcon } />
                                                        </div>
                                                        <div className="title_logo">Add cover</div>
                                                    </label>
                                                    {
                                                        endCoverStatus ?
                                                        <div className="div_icon_add icon_project" style={ { backgroundImage: 'url("' + URL.createObjectURL(endCover) + '")' } } onClick={ ()=>{ props.OpenFile('img', URL.createObjectURL(endCover)) } } />
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                }
                            </> 
                            : 
                            <div className="alert">Project creation limit reached</div>
                        }     
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
