import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { GetUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Reg_AltStatus } from "services/RegisterData";

export default function PopUP_Confirmation(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function TypeAlt(){
        props.setLoading(true);
        Reg_AltStatus(userId, modalData.origin, modalData.type, modalData.id, ()=>{ props.CallbackSuccess(); ClosePopUp() }, ()=>{ props.CallbackError(); ClosePopUp() });
    }

    function ClosePopUp(){
        SetModalState('Historic', false);
        SetModalState('Confirmation', false);
    }

    function TypeRemoval(){
        switch(modalData.type){
            case "disable_project_0":
                return (
                    <div>
                        Deactivate project:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "disable_project_1":
                return (
                    <div>
                        Reactivate project:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "block_user_0":
                return (
                    <div>
                        Deactivate user:
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "block_user_1":
                return (
                    <div>
                        Reactivate user:
                        <div>{ modalData.name }</div>
                    </div>
                );
            default:
                return (
                    <div>
                        Deletar:
                        <div>{ modalData.name }</div>
                    </div>
                );
        }
    }

    useEffect(()=>{
        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    return (
        (showPopUp ?
            <>
                <div className="PopUp PopUpCenter">
                    <div className="all Confirmation">
                        <div className="div_data">
                            <div className="title">
                                { TypeRemoval() }
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content div_confirmation">
                                <div onClick={ ()=>{ TypeAlt(); } }>
                                    <div className="opt_select yes_update">Sim</div>
                                </div>
                                <div onClick={ ()=>{ ClosePopUp(); } }>
                                    <div className="opt_select not_update">Não</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
