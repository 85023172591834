import { useState, useEffect } from "react";

import './Report.css';

import Comp_SaveOrEdit from "components/SaveOrEdit";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete, Svg_Eye } from "services/SvgFile";

import { colorIcon } from "fixedData";
import PopUP_Report from "popUp/Report";

export default function Page_Report(props){

    const [ dataPage, setDataPage ] = useState(GetDataPage('report'));
    const [ listData, setData ] = useState(GetDataPage('report'));

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "report", "type": "delete_data", "id": id, "name": value });
        SetModalState('Confirmation', true);
    }

    function OpenData(elem){
        SetModalData('Report', { "data": elem });
        SetModalState('Report', true);
    }

    function ClickDownload(){
        const data = [];
        data.push([
            "Name", "Last name", "Company Name", "Phone", "Email", "Where we met", "Message", "Contact phone", "Contact email", "Date"
        ]);

        GetDataPage('report').map((elem, index)=>{
            data.push([
                elem.name, elem.last_name, elem.company_name, elem.phone, elem.email, elem.heard_about, elem.additional_message, elem.contact_phone, elem.contact_email, elem.register_date
            ])
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        data.forEach(function(rowArray) {
            let row = rowArray.join(";");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "report.csv");
        document.body.appendChild(link);
        link.click();        
        document.body.removeChild(link);
    }

    useEffect(()=>{
        RegisterDataPage('report', setDataPage);
        return ()=>{
            UnRegisterDataPage('report', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setData(GetDataPage('report'));
    }, [dataPage]);

    return(
        <div className="InfPage">
            <Comp_SaveOrEdit type="" title="Report" subtitle="List of received emails" />
            
            <div className="download">
                <div className="" onClick={ ()=>{ ClickDownload() } }>
                    Download CSV
                </div>
            </div>

            <table cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <td className="border_top" width="20" align="center">#</td>
                        <td className="border_top">Name</td>
                        <td className="border_top">Last name</td>
                        <td className="border_top">Company</td>
                        <td className="border_top" width="180">Email</td>
                        <td className="border_top" width="40">Phone</td>
                        <td className="border_top" width="100">Where we met</td>
                        <td className="border_top">Message</td>
                        <td className="border_top" width="100">Date</td>
                        <td className="border_top" width="20" style={ { textAlign: "right" } }>#</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        listData.map((elem, index)=>{
                            return(
                                <tr key={ index }>
                                    <td>{ index + 1 }</td>
                                    <td>{ elem.name }</td>
                                    <td>{ elem.last_name }</td>
                                    <td>{ elem.company_name }</td>
                                    <td>                                        
                                        <div className="">{ elem.email }</div>
                                        <div className="">Contact: { elem.contact_email }</div>
                                    </td>
                                    <td>
                                        <div className="">{ elem.phone }</div>
                                        <div className="">Contact: { elem.contact_phone }</div>
                                    </td>
                                    <td>{ elem.heard_about }</td>
                                    <td>{ elem.additional_message }</td>
                                    <td>{ elem.register_date }</td>
                                    <td>
                                        <div className="div_opt">       
                                            <div className="" onClick={ ()=>{ DeleteData(elem.id, elem.name); } }>
                                                <Svg_Delete className="icons" color={ colorIcon } />
                                            </div> |
                                            <div className="" onClick={ ()=>{ OpenData(elem); } }>
                                                <Svg_Eye className="icons" color={ colorIcon } />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            <PopUP_Report />
        </div>
    )
}