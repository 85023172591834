import { useState, useEffect } from "react";

import './AddData.css';

import { Svg_AddData, Svg_Historic } from "services/SvgFile";

import { colorIcon } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";

export default function Comp_AddData(props){

    function ShowHistoric(){
        SetModalData('Historic', { "list": props.listDisabled });
        SetModalState('Historic', true);
    }

    return(
        <div className="Comp_AddData">                            
            <div className="div_btn">
                {
                    props.btn === "Change your avatar" ?
                    <label className="btn">
                        <input type="file" onChange={ (e)=>{ props.function(e.target.files[0]); } } accept="image/*" />
                        <div className="">
                            <Svg_AddData className="icons" color={ colorIcon } />
                        </div>
                        <div className="">{ props.btn }</div>
                    </label>
                    :
                    <div className="btn" onClick={ ()=>{ props.function(props.id) } }>
                        <div className="">
                            <Svg_AddData className="icons" color={ colorIcon } />
                        </div>
                        <div className="">{ props.btn }</div>                
                    </div>
                }
            </div>
            {
                props.listDisabled.length > 0 ?
                <div className="remove_all" onClick={ ()=>{ ShowHistoric() } }>
                    <div className="">
                        <Svg_Historic className="icons" color={ colorIcon } />
                    </div>
                    <div className="title_remove">Deactivated users</div>
                </div>
                : null
            }
        </div>
    )
}